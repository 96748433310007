import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "../Component/Footer";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

export default function Contact() {
  const classes = useStyles();

  const styles = {
    center: {
      textAlign: "justify",
      fontSize: "27px",
      color: "white",
      paddingTop: "20%",
      width: "40%",
      fontFamily: "ariral,sans-serif",
    },
    egnaro: {
      color: "#ff8a00",
    },
  };

  return (
    <div className="backgroundColor">
      <Container fixed>
        <div style={{ "text-align": "left", "padding-top": "7%" }}>
          <Typography
            variant="h6"
            component="h2"
            style={{
              color: "#ff8a00",
              "font-size": "47px",
              float: "left",
              "font-family": "ariral,sans-serif",
              "font-weight": "bold",
            }}
          >
            CONTACT US
          </Typography>
          <Typography component="h1" variant="h5" style={styles.center}>
            If you have a question or a comment, please email us at
            <span style={styles.egnaro} className="zoom">
              {" "}
              info@egnaroinc.com
            </span>
          </Typography>
        </div>
        <Typography
          component="h1"
          variant="h6"
          style={{
            color: "white",
            "padding-top": "10%",
            "padding-bottom":"10%",
            "font-size": "20px",
            "font-family": "ariral,sans-serif",
          }}
        >
          <p style={{ color: "#ff8a00", "font-weight": "bold" }}>
            CONTACT DETAILS
          </p>
          <p>India Operations:</p>
          <p>A1, Ground Floor, Span Ventures-SEZ, Embassy Techzone,</p>
          <p>Pollachi Main Road, Eachanari,</p>
          <p>Coimbatore - 641021</p>
          <br></br>
          <p>405, Alekhya Palmwoods, </p>
          <p>Nanakramguda, Gachibowli,</p>
          <p>Hyderabad - 500032</p>
        </Typography>
      </Container>
    </div>
  );
}
