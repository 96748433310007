import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Footer from "../Component/Footer"
import Fade from 'react-reveal/Fade';
import IconDetails from "../Component/IconDetails"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  }
}));

export default function Contact() {
  const classes = useStyles();
  const styles={
    center:{
      textAlign:"justify",
      fontSize:'67px',
      color:'black',
      fontWeight:'bold',
      color:'#ff8a00'
    },
    egnaro:{
      color:'#ff8a00'
    },
    textFont:{
      fontSize:'24px',
      color:'white',
      textAlign:'left',
      paddingLeft:'20px',
      paddingTop:'32%',
      fontFamily:'ariral,sans-serif'
    }
  }

//  document.body.style.backgroundImage=`url(${careers})`
  return (
    <div>
    <div className="commonbackground">
    <Container fixed>
      <CssBaseline />

      <div  style={{'text-align':'justify','padding-left':'59%','width':'89%','padding-top':"2%"}}>

        <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'41px','float':'right','font-family':'ariral,sans-serif','font-weight':'bold','padding-left':'20px','padding-right':'20px'}}>
          HOW
          TO
          APPLY
        </Typography>
            <Typography component="h6" variant="p" style={styles.textFont}>
                Egnaro is an equal-opportunity employer with an eye towards excellence. If you think that you’re a good fit, please send your resume to
                <span style={styles.egnaro} className="zoom"> careers@egnaroinc.com</span>
            </Typography>
        </div>
        <Grid Container style={{'text-align':'center','padding-top':'24%'}}>
          <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'47px','font-family':'ariral,sans-serif','font-weight':'bold'}}>
            WORK
            ENVIRONMENT
          </Typography>
          <Typography variant="h6" component="p" style={{'color':'white','font-size':'21px','width':'81%','padding-top':'5%','padding-left':'27%','font-family':'ariral,sans-serif'}}>
          At Egnaro, we believe productivity is where comfort is. We keep our work environment informal but professional. We being foodies and fun lovers, you will be pampered to free healthy food and awesome perks including recreational facilities. Employees are also given the flexibility to collectively evolve their environment as they deem fit.

          </Typography>
        </Grid>

        <div style={{'padding-top':"20%"}}></div>

        <Footer size={4} color="black" mobile={false}/>
        <div style={{'padding-top':"10%"}}></div>


    </Container>
    </div>

    </div>
  );
}
