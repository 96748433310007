import React, { Component } from 'react';
import Config from "../Constants/Constants"
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import Footer from "../Component/Footer"
import Button from '@material-ui/core/Button';
import logo from "../img/favicon.png"

import IconDetails from "../Component/IconDetails"


function GetCanvas(){
    return null;
}

class Home extends Component{
      constructor(props){
        super(props)
        this.state={
          mobile:false
        }
//        alert(navigator.platform);
        if (typeof window.orientation !== 'undefined') {
//          alert(window.orientation)
          this.state.mobile=true
        }
      //  alert(this.state.mobile)
      }

      render(){

        const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
   margin: theme.spacing(1),
   color:"white"
 },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'blanchedalmond'
  },
}));
const classes = useStyles;
const styles={
  center:{
    textAlign:"center",
    fontSize:'large',
    fontWeight:'bold'

  },
  title:{
    textAlign:'justify',
    fontSize:'large'
  },
  highLight:{
    fontSize:'medium',
    fontWeight:'bold'
  },
  egnaro:{
    color:'chocolate',
    textAlign:'center',
    fontSize:'large',

  },
  textFont:{
    fontSize:'large',
    textAlign:'justify'
  },
  content:{
    backgroundColor:'whitesmoke'
  }
}
          const automatterUrl=Config.product[0].url;
          const appifyUrl=Config.product[1].url;
          const warpUrl=Config.product[2].url;
          const chowkidarUrl=Config.product[3].url;
              return(
                  <div className='backgroundColor'>
                  <React.Fragment>


                        <CssBaseline />


                            <Container fixed >

                              <Grid Container>
                                <div style={{'padding-top':'18%'}}>
                                <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'47px','font-family':'ariral,sans-serif'}}>
                                  AT
                                </Typography>
                                <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'74px','font-weight':"bold",'font-family':'ariral,sans-serif','margin-top':'-2%'}}>
                                  EGNARO
                                </Typography>
                                </div>
                                <Typography variant="h6" component="p" style={{'color':'white','font-size':'17px','width':'37%','padding-top':'66px','font-family':'ariral,sans-serif','text-align':'left'}}>
                                We have an ecosystem of self-driven and self-responsible individuals in the truest sense. Neither to command nor to submit, but to work together as equals, shoulder to shoulder, trusting each other and being honest to ourselves and to each other.
                                </Typography>
                                <Typography variant="h6" component="h5" style={{'color':'#ff8a00','font-size':'20px','padding-top':'11%','text-align':'left','font-family':'ariral,sans-serif'}}>
                                 We live by the values that are aspired for.
                                </Typography>
                              </Grid>

                                   <Grid>
                                   <div  style={{'text-align':'left','padding-left':'57%','width':'90%','padding-top':"17%"}}>
                                          <Typography variant="h6" component="p" style={{'font-size':'67px','color':"#ff8a00",'font-family':"ariral,sans-serif",'padding-left':'20px','font-weight':'bold'}}>
                                             OUR
                                          </Typography>
                                          <Typography variant="h6" component="p" style={{'font-size':'67px','color':"#ff8a00",'font-family':"ariral,sans-serif",'padding-left':'20px','font-weight':'bold','margin-top':'-10%'}}>
                                          MISSION
                                          </Typography>
                                          <Typography variant='h6' component='p' style={{'font-size':'17px','color':'white','font-family':'ariral,sans-serif','padding-left':'20px','padding-right':'20px','text-align':'left'}}>
                                              Mathematics, Statistics, Machine Learning, AI, Cluster computation and many other cutting edge technologies are being used for just selling ads.
                                              Egnaro, Aims at employing these in transforming human lives.
                                          </Typography>


                                   </div>
                                   </Grid>
                                   <center>
                                   <Typography variant='h6' component="h1" style={{'font-size':'47px','color':"white",'text-align':"center",'padding-top':'20%','font-weight':'bold','font-family':"ariral,sans-serif"}}>
                                     PRODUCTS
                                   </Typography>

                                   <Grid container style={{'padding-left':"27%",'padding-top':"2%",'color':'white','text-align':'center'}}>
                                                 <IconDetails  data={Config.product} size={2} mobile={false} mode="product"/>
                                  </Grid>

                                            <Typography variant='h6' component="h1" style={{'font-size':'47px','color':"white",'text-align':"center",'padding-top':'4%','font-weight':'bold','text-align':'center','font-family':"ariral,sans-serif"}}>
                                              SOLUTIONS
                                            </Typography>
                                            <Grid container style={{'padding-left':"27%",'padding-top':"2%",'color':'white','text-align':'center','padding-left':'34%'}}>
                                                          <IconDetails  data={Config.solution} size={2}  mobile={false} mode="solution"/>
                                           </Grid>
                                    </center>
                                    <div style={{'padding-top':"8%"}}></div>
                                  <Footer size={4} color="black" mobile={false}/>
                                  <div style={{'padding-top':"10%"}}></div>
                        </Container>
                        </React.Fragment>

                  </div>
              );
      }
}

export default Home ;
