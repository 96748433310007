import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import WebHome from "./Layout/Home"
import MobileHome from "./Layout/CommonHome"

import About from "./Layout/About"
import Product from "./Layout/Product"
import Contact from "./Layout/Contact"
import Menubar from "./Component/Menubar"
import Config from "./Constants/Constants"
import Solution from "./Layout/Solutions"
import Career from "./Layout/Career"

import MobileCarrer from "./Layout/MobileCareer"
import MobileContact from "./Layout/MobileContact"
import MobileProduct from "./Layout/MobileProduct"
import { isMobile, isBrowser } from "react-device-detect"

class App extends Component {

        constructor(props) {
                super(props)
                this.state = {
                        mobilePlatform: false,
                        home: WebHome,
                        career: Career,
                        contact: Contact,
                        product: Product,
                        solution: Solution
                }
              
        }

        render() {
                return (
                        <Router>
                                <div style={{ 'background': 'black' }}>
                                        <Menubar sideMenu={Config.homeMenu} mobile={this.state.mobilePlatform} />
                                        <Route exact path="/" component={isBrowser ? WebHome : MobileHome} />
                                        <Route exact path="/home" component={isBrowser ? WebHome : MobileHome} />
                                        <Route exact path="/product/:id" component={isBrowser ? Product : MobileProduct} />
                                        <Route exact path="/solutions/:id" component={isBrowser ? Solution : MobileProduct} />
                                        <Route exact path="/contact" component={isBrowser ? Contact : MobileContact} />
                                        <Route path="/career" component={isBrowser ? Career : MobileCarrer} />
                                </div>
                        </Router>
                );
        }
}

export default App;
