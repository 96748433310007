

import automatter from "../img/ICONS/Automatter.png"
import warp from "../img/ICONS/warp.png"
import appify from "../img/ICONS/appify.png"
import garuda from "../img/ICONS/garuda.png"

import cloudSolution from "../img/icons/cloud.png"
import sdn from "../img/icons/sdn.png"
import hpc from "../img/ICONS/hpc.png"
import rd from "../img/ICONS/rd.png"
import opticalNetworking from "../img/ICONS/Optical-Networking.png"
const homeMenu=[
  {
    "url":'home',
    "title":'Home',
    "subMenu":false
  },
//  {
  //   "url":'about',
  //   "title":"About Us",
  //   "subMenu":false
  // },
  {
      "url":"product",
      "title":"Product",
      "children":[
        {
          "url":'automatter',
          "title":'Automatter'
        },
        {
          "url":"appify",
          "title":"Appify"
        },
        {
        "url":'warp',
        "title":"WARP"
      },
      {
        "url":"garuda",
        "title":"Garuda"
      }
      ],
      "subMenu":true
  },
  {
      "url":'solutions',
      "title":'Solutions',
      "subMenu":true,
      "children":[
        {
          "url":'opticalnetworking',
          "title":'Optical Networking '
        },
        // {
        //   "url":'enterprisecloudsolutions',
        //   "title":'Enterprise cloud solutions'
        // },
        {
          "url":"highperformancecomputing",
          "title":"High Performance Computing(HPC)"
        },
        {
          "url":'r&doutsourcing',
          "title":'R&D outsourcing'
        }
        // {
        //   "url":"sdncontroller",
        //   "title":"SDN Controller"
        // }
      ]

  },
  {
    "url":"career",
    "title":"Career",
    "subMenu":false
  },
  {
      "url":"contact",
      "title":"Contact Us",
      "subMenu":false
  }
];

const text='text';
const file="file";
const button="button";
const title="Egnaro"
const origin=window.location.origin+'/';
const solutionName="solutions/";
const productName="product";
const automatterName="automatter";
const appifyName="appify";
const warpName="warp";
const garudaName="garuda";
const product=[
  {
    "product":"automatter",
    "title":"Automatter",
    "content":[
      "Automatter is an end to end framework to develop and host Software as a service, IoT as a service and create robust and scalable web applications with minimal effort. The core ‘mantra’ of Automatter is to free developers from worrying about infrastructure decisions, fundamental web services, support services and help them focus on solving the domain problems.",
      "The rich set of microservices and libraries in Automatter includes, Authentication, RBAC, distributed logging, container manager, a generic data modeling, and common UI actions, most common workflows needed for web apps and IoT applications. Additional attention is given to optimize these libraries to suit enterprise web migration needs."
      
    ],
    "subchildren":[
    ],
    "img":automatter,
    "url":origin+productName+"/"+automatterName
  },
  {
    "product":'appify',
    "title":"Appify",
    "content":[
      "Instant conversion of legacy desktop application to scalable, highly available, collaborative web application.",
      "The appified version of the desktop application will have the following services integrated to the application without any code change."],
      "subchildren":[
        "User story with Authentication and Authorisation",
        "Cluster manager to handle very high load",
        "Distributed logging",
        "Persistence store for user and application data",
        "Collaboration service",
        "Failure management service"
      ],
      "img":appify,
      "url":origin+productName+"/"+appifyName

  },
  {
    "product":"warp",
    "title":"WARP",
    "content":[
      "You own the website, you own the user and traffic data, and only you should see the analytics results.",
      "WARP is a complete Web Analytics solution that can be self-hosted as private cloud or in your backyard servers.",
      "End to end control of trackers, data and the analysed results without anyone peeking at your business metrics and leech from them."
    ],
    "img":warp,
    "url":origin+productName+"/"+warpName

  },
  {
    "product":'garuda',
    "title":"Garuda",
    "content":[
      "A robust, scalable distributed computation infrastructure built using crowd-sourced machines from the public, to employ Artificial Intelligence, and High-Performance Computation, and integrating to IoT, to reduce work burden in public sector."
    ],
    "img":garuda,
    "url":origin+productName+"/"+garudaName
  }
]
const solutions=[
  // {
  //   "solution":"sdncontroller",
  //   "title":"SDN Controller",
  //   "content":[
  //     "Write up to be provided"
  //   ],
  //   "subchildren":[],
  //   "img":sdn,
  //   "url":''
  // },
  {
    "solution":"opticalnetworking",
    "title":"Optical Networking",
    "content":[
      "State of the Art User Interface (UI) design experts engage in designing the websites, computers, mobile devices, and software applications with the focus being on user’s experience and interaction. The main goal of user interface experts is to make the interaction of the user as simple and efficient as possible and also to accomplish the user goals which are also known as user-centered design",
      "Our company provides UI experts with good user interface design that facilitates finishing the tasks at hand without drawing any attention that is unnecessary to itself. We use graphic design to be utilized supporting its usability and performing certain interactions. We provide the design process in a manner that balances the technical function ability and the visual elements present in it. It is created in such a way that the system is not only intuitive and operational but also becomes usable and adaptable to the changing user needs.",
      "UI experts need to have a good understanding of the needs of the user. We understand you needs based on your work environment and not just user inputs. We undertake the following steps for UI interface design"
    ],
    "subchildren":[
      "Gathering the functional requirements",
      "User and task analysis",
      "Information architecture",
      "Prototyping",
      "Usability inspection and testing",
      "Graphic interface design"
    ],
    "img":opticalNetworking,
    "url":origin+solutionName+"opticalnetworking"

  },

  // {
  //   "solution":"enterprisecloudsolutions",
  //   "title":"Enterprise Cloud Solutions",
  //   "content":[
  //     "Write up to be provided"
  //   ],
  //   "subchildren":[],
  //
  //   "img":cloudSolution,
  //   "url":''
  //
  // },
  {
    "solution":"highperformancecomputing",
    "title":"High Performance Computing(HPC)",
    "content":[
      "Rendering is a process which generates an image from a model through the means of computer programs. Distributed rendering is an application of parallel programming. A single render job is distributed within a single frame across various computers in a network. Rendering graphics is a process that requires massive computational resources from the complex scene that arises out of scientific visualization, CAD applications, medical visualization and also virtual reality. Distributed rendering is used for two reasons. Firstly performance scaling helps in allowing the frames to be rendered more quickly and secondly, data scaling will allow larger data sets to be visualized. We engage into rendering in a way that divides the frame into smaller buckets and to give each machine to render a number of them. Results are obtained as the next step and combine these into final image. We providing a framework with server farms to render models in distributed fashion.",
       "The process is done completely through TCP/IP protocol which is the standard protocol of the Internet and also the most common protocol that is supported by the hardware. The distribution management is divided into two i.e. Render Clients and Render Servers. Our company uses this distribution management through these. Render clients represents the computer that the user would be currently using and from which the rendering is initiated. It would divide the frames to rendering regions which would spread it into Render Servers. These servers help in processing and collect the results. Render Servers is also a computer which a part of render farm. The process starts as requesting the data from the render client, processing it and sending out the results."
    ],
    "subchildren":[],
    "img":hpc,
    "url":origin+solutionName+"highperformancecomputing"

  },
  {
    "solution":"r&doutsourcing",
    "title":"R&D outsourcing",
    "content":[
      "Outsourcing various sections of the business functions of an organization has been happening for many years now. It was mostly the information technology area that was chosen for outsourcing, but today research and development field is also outsourced. Research and development department in an organization is built with an objective to obtain new knowledge, which is useful for the business so that it could result in creating new products, processing the systems and services for higher productivity and sales of the company.",
      " Our company engages in effective R&D outsourcing that is both beneficial and quick to respond. Some of the benefits that a client prospers from R&D outsourcing are- quick response to an offering from a competitor, finding and fixing the bugs in the code, accelerating a project that is running overtime, overcoming the departure of the important staff and overcoming the quality issues. Through outsourcing companies are likely to become more globally integrated and they get adapted to the global demand and supply of talent. Outsourcing R&D provides a company with a move to reduce the cost that occurs if it is carried in-house. The overheads in a department increased the cost of R&D and become a burden on the organization. Our company helps in R&D outsourcing for you with reduced costs and higher quality. The strategic drivers of outsourcing R&D would be- freedom to senior management, there is mitigation of risks, improved customer-end satisfaction, and reduction in staff overheads."
    ],
    "subchildren":[],
    "img":rd,
    "url":origin+solutionName+"r&doutsourcing"

  }



]
const config={
    homeMenu:homeMenu,
    title:title,
    solution:solutions,
    inputField:{
      text:text,
      button:button,
      file:file
    },
    product:product
  }
export default config;
