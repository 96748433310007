import React, { Component } from 'react';
import Config from "../Constants/Constants"
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Footer from "../Component/Footer"
import Button from '@material-ui/core/Button';
import logo from "../img/favicon.png"
 import background from "../img/IMAGES/hands.png"



import IconDetails from "../Component/IconDetails"
function GetCanvas(){
    return null;
}

class Home extends Component{
      constructor(props){
        super(props)
        this.state={
          mobile:false
        }
//        alert(navigator.platform);
        if (typeof window.orientation !== 'undefined') {
//          alert(window.orientation)
          this.state.mobile=true
          document.body.style.backgroundImage='none'
          document.body.style.background="black"

        }
      //  alert(this.state.mobile)
      }

      render(){

        const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
   margin: theme.spacing(1),
   color:"white"
 },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'blanchedalmond'
  },
}));
const classes = useStyles;
const styles={
  center:{
    textAlign:"center",
    fontSize:'large',
    fontWeight:'bold'

  },
  title:{
    textAlign:'justify',
    fontSize:'large'
  },
  highLight:{
    fontSize:'medium',
    fontWeight:'bold'
  },
  egnaro:{
    color:'chocolate',
    textAlign:'center',
    fontSize:'large',

  },
  textFont:{
    fontSize:'large',
    textAlign:'justify'
  },
  content:{
    backgroundColor:'whitesmoke'
  }
}
          const automatterUrl=Config.product[0].url;
          const appifyUrl=Config.product[1].url;
          const warpUrl=Config.product[2].url;
          const chowkidarUrl=Config.product[3].url;
              return(
                  <div style={{'background':'black'}}>
                  <Container>
                                  <div style={{'padding-right':'40px','padding-left':'40px'}}>
                                  <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'27px','font-family':'ariral,sans-serif'}}>
                                  AT
                                </Typography>
                                <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'54px','font-family':'ariral,sans-serif'}}>
                                  EGNARO
                                </Typography>
                                <Typography variant="h6" component="p" style={{'color':'white','font-size':'17px','width':'100%','text-align':"justify",'font-family':'ariral,sans-serif'}}>
                                We have an ecosystem of self-driven and self-responsible individuals in the truest sense. Neither to command nor to submit, but to work together as equals, shoulder to shoulder, trusting each other and being honest to ourselves and to each other. We live by the values that are aspired for.
                                </Typography>
                                <Typography variant="h6" component="h5" style={{'padding-top':'22%','color':'white','font-size':'20px','font-family':'ariral,sans-serif'}}>
                                 We live by the values that are aspired for.
                                </Typography>
                              </div>

                                   <Grid>
                                   <div  style={{'text-align':'justify','padding-top':"10%",'padding-right':'40px','padding-left':'40px','font-family':'ariral,sans-serif'}}>
                                          <Typography variant="h6" component="h2" style={{'font-size':'27px','color':"#ff8a00",'font-family':'ariral,sans-serif'}}>
                                             OUR MISSION
                                          </Typography>
                                          <Typography variant='h6' component='p' style={{'font-size':'16px','color':'white','font-family':'ariral,sans-serif'}}>
                                              Mathematics,Statistics,Machine Learning,AI,Cluster computation and many other cutting edge technologies are being used for just selling ads.
                                              Egnaro, Aims at employing these in transforming human lives.
                                          </Typography>
                                          <br/>
                                          <br/>


                                   </div>
                                   <br/>
                                   <br/>
                                   <br/>
                                   <br/>
                                   </Grid>

                                   <Typography variant='h6' component="h1" style={{'font-size':'31px','color':"#ff8a00",'text-align':"center",'padding-top':'9%','padding-right':'40px','padding-left':'40px','font-family':'ariral,sans-serif'}}>
                                     PRODUCTS
                                   </Typography>
                                   <Grid container >
                                             <Grid  container item={24} spacing={1} style={{'padding-top':"13%",'color':'white','font-family':'ariral,sans-serif'}}>
                                                 <IconDetails  data={Config.product} size={6} mobile={true}/>
                                             </Grid>
                                   </Grid>
                                            <Typography variant='h6' component="h1" style={{'font-size':'31px','color':"#ff8a00",'text-align':"center",'padding-top':'9%','padding-right':'40px','padding-left':'40px','font-family':'ariral,sans-serif'}}>
                                              SOLUTIONS
                                            </Typography>
                                            <Grid container >
                                                      <Grid  container item={24} spacing={1} style={{'padding-top':"13%",'color':'white','font-family':'ariral,sans-serif'}}>
                                                          <IconDetails  data={Config.solution} size={6} mobile={true}/>
                                                      </Grid>
                                           </Grid>
                                   <br/>
                                   <br/>
                              <Footer size={6} color="white" mobile={true}/>
                        </Container>

                  </div>
              );
      }
}

export default Home ;
