import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import logo from "../img/favicon.png"
import { Link } from "react-router-dom";
import egnaro from "../img/logo.png"
import {isMobile,isBrowser} from "react-device-detect"
function SubMenu(subMenus){
      var subMenu=subMenus.subMenu;
      var menu=[];
      var mobileStyle=subMenus.mobile?{'color':"white",'font-family':'ariral,sans-serif'}:{"color":'white','font-family':'ariral,sans-serif'}
      for(var  i in subMenu){
              var url=window.location.origin+"/"+subMenus.baseUrl+"/"+subMenu[i].url;
              menu.push(
                    <li id="dropdown">
                    <a className="dropdown-item" style={mobileStyle} href={url}>{subMenu[i].title}</a>
                    <br/>
                    </li>
              )
      }
      return menu;
}
function SideMenu(menu){
        var sideMenu=menu.sideMenu;
        var styles=menu.css;
        var listMenu=[];
        var history=window.location.pathname.split('/')
        var aTagStyle={}
        console.log(history[1].indexOf('product'));
        var dropdownTag={}
        console.log(history);
        for(var i in sideMenu){
                var url=window.location.origin+"/"+sideMenu[i].url;
               if(history[1].indexOf(sideMenu[i].url)!=-1){
                 console.log(sideMenu[i]);
                  console.log(history[1].indexOf(sideMenu[i].url));
                  dropdownTag={'color':'white','font-size':'17px','font-family':'ariral,sans-serif','border-bottom':'3px solid #ff8a00','float':"left"}
                  aTagStyle={'color':'white','font-size':'17px','font-family':'ariral,sans-serif','border-bottom':'3px solid #ff8a00','float':"left"}

                }
                else {
                    aTagStyle={'color':'white','font-size':'17px','font-family':'ariral,sans-serif'}
                    dropdownTag={'color':'white','font-size':'17px','font-family':'ariral,sans-serif'}
                }
                listMenu.push(
                            <ul className="nav navbar-nav">
                                {sideMenu[i].subMenu?
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" style={dropdownTag} href={url} id="navbardrop" data-toggle="dropdown">
                                        {sideMenu[i].title}
                                        <span class="glyphicon glyphicon-menu-down" style={{'marginLeft':"10px",'font-size':"12px"}}></span>

                                        </a>
                                          <div class="dropdown-menu" style={{'background':'rgba(0,0,0,0.8)'}}>
                                            <SubMenu subMenu={sideMenu[i].children} baseUrl={sideMenu[i].url} mobile={menu.mobile}/>
                                         </div>
                                  </li>
                                 :
                                  <li>
                                    <a href={url} style={aTagStyle}>
                                    {sideMenu[i].title}</a>
                                  </li>
                              }
                              </ul>
                )
        }
        return listMenu;
}
function Menubar(props){
  const sideMenu=props.sideMenu
  const origin=window.location.origin
    return (
        <React.Fragment>
        <CssBaseline />
                <nav className="navbar navbar-inverse navbar-fixed-top" style={{'background':'rgba(0,0,0,0.8)','backdrop-filter':' saturate(180%) blur(20px)'}}>
                      <div classNameName="container-fluid">
                          <div className="navbar-header" >
                          <a href={origin}>
                          <img src={egnaro} style={{'margin-left':' 35%'}} className="navbar-brand"/>
                          </a>

                                <button type="button" style={{'background-color':'black','font-weight':'bold'}} className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                      <span className="icon-bar" style={{'color':'black','font-weight':'bold'}}> </span>
                                      <span className="icon-bar" style={{'color':'black','font-weight':'bold'}}></span>

                                </button>

                          </div>
                          <div className="collapse navbar-collapse" id="myNavbar" >

                          <ul className="nav navbar-nav" style={ isBrowser? {'margin-left':"35%"}:{'margin-left':'0%'}}>

                            <SideMenu  sideMenu={sideMenu} mobile={props.mobile}/>
                          </ul>
                        </div>
                  </div>

            </nav>
        <main>
          <br/><br/>
          <br/><br/>
          <br/><br/>
          <br/><br/>
        </main>
</React.Fragment>
)
}

export default Menubar;
