import React, { Component } from 'react';
import Config from "../Constants/Constants"
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Menubar from "../Component/Menubar"
import Footer from "../Component/Footer"
import Fade from 'react-reveal/Fade';

class Product extends Component{
    constructor(props){
      super(props)
      console.log(props);
      var details=""
      var path=props.match.path
      var solution=false
      if(path.includes('solution')){
        details=props.match.params.id
        solution=true
      }
      else if(path.includes('product')){
        details=props.match.params.id
      }
      this.state={
            product:details,
            load:true,
            solution:solution
      }
    }
    componentDidUpdate(){
      window.location.href=window.location.href;
    }
    render(){
      const useStyles = makeStyles(theme => ({
root: {
  flexGrow: 1,
},
paper: {
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: 'blanchedalmond',
  fontSize:'10px'
},
}));
const classes = useStyles;

          return(
            <div>
            <React.Fragment>
                  <CssBaseline />
                      <Container fixed>
                            <Grid item xs={12}>
                                        <Fade left>
                                        <MatchProduct product={this.state.product} isProduct={this.state.solution}/>
                                        </Fade>
                            </Grid>
                            <Footer size={6} color="white" mobile={true}/>

                      </Container>
            </React.Fragment>
            </div>
          )
    }
}
function ProductContent(props){
    var content=props.content;
    var subcontent=props.subchildren;
    var listContent=[];
    var listSubContent=[];
    for(var j in subcontent){
          listSubContent.push(
            <li style={{'font-family':'ariral,sans-serif'}}>
            {subcontent[j]}
            </li>
          );
    }
    for(var i in content){
          listContent.push(
            <ul>
            <Typography variant="p" component="p" style={{'font-size':'17px','text-align':'justify','padding-right':'19px','color':'white','padding-top':'10%','font-family':'ariral,sans-serif'}}>
            {content[i]}
            </Typography>
            </ul>
          )
    }
    var list=<div>{listContent}<ul style={{'font-size':'17px','margin-left':'74px','padding-top':'10%'}}>{listSubContent}</ul>
        <Typography style={{ 'fontSize': "17px" ,'marginLeft':"42px",'color':"white"}}>
      For demo contact <span style={{ 'color': "orange" }}> info@egnaroinc.com</span>
    </Typography>
    </div>

    return list;
}
function MatchProduct(props){
    var indentation={
      padding:{
//        paddingLeft:'127px',
        color:'#ff8a00',
        textAlign:'center',
        textTransform:"uppercase",
        fontWeight:'bold',
        fontFamily:'ariral,sans-serif'
      }
    }
    var product=props.product
    var listProduct=[];
    var productArray=props.isProduct? Config.solution :Config.product
    for(var i in productArray){
        if(productArray[i].product===product||productArray[i].solution==product){
          listProduct.push(
            <div style={{'text-align':'center'}}>
            <Typography variant="h2" component="h2" className="UpperTransform" style={indentation.padding}>
            {productArray[i].title}
            </Typography>
            <img style={{'width':'56%','padding-top':'14%'}} src={productArray[i].img} alt={productArray[i].product}/>

            <ProductContent content={productArray[i].content} subchildren={productArray[i].subchildren}/>
            </div>
          )
        }
      }

    return listProduct;
}
export default Product
