import React, { Component } from 'react';
import Config from "../Constants/Constants"
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Menubar from "../Component/Menubar"
import Footer from "../Component/Footer"
import warp from "../img/product/warp.jpg"
import automatter from "../img/product/automatter.jpg"
import garuda from "../img/product/garuda.jpg"
import appify from "../img/product/appify.jpg"
import "../App.css"
class Product extends Component {
  constructor(props) {
    super(props)
    console.log(props);
    var product = props.match.params.id
    // var splitData=product.split(':')
    this.state = {
      product: product,
      load: true,
      imageUrl: this.matchImg(product)
    }
  }
  matchImg(product) {
    if (product == "appify") {
      return appify
    }
    else if (product == "warp") {
      return warp
    }
    else if (product == "garuda") {
      return garuda
    }
    else if (product == "automatter") {
      return automatter
    }
  }
  componentDidUpdate() {
    window.location.href = window.location.href;
  }
  render() {
    const useStyles = makeStyles(theme => ({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'blanchedalmond',
        fontSize: '10px'
      },
    }));
    const classes = useStyles;
    console.log(this.state.product);
    var paddingDistance = {}
    if (this.state.product === "garuda") {
      //      34
      paddingDistance = { 'padding-top': "38%" }
    }
    else if (this.state.product === "warp") {
      paddingDistance = { 'padding-top': "33%" }
    }
    else if (this.state.product === "automatter") {
      paddingDistance = { 'padding-top': "27%" }
    }
    else if (this.state.product === "appify") {
      paddingDistance = { 'padding-top': "24%" }
    }
    console.log(this.state);
    return (
      <div style={{ "background": `url(${this.state.imageUrl})`, 'background-repeat': 'no-repeat,repeat', 'background-position': 'top' }}>
        <React.Fragment>
          <CssBaseline />
          <Container fixed>
            <Grid item xs={12}>
              <MatchProduct product={this.state.product} />

            </Grid>
            <div style={paddingDistance}>
            </div>

            <Footer size={4} color="black" mobile={false} />
            <div style={{ 'padding-top': "10%" }}></div>

          </Container>
        </React.Fragment>
      </div>
    )
  }
}
function ProductContent(props) {
  var content = props.content;
  var subcontent = props.subchildren;
  var listContent = [];
  var listSubContent = [];
  for (var j in subcontent) {
    listSubContent.push(
      <li style={{ 'font-size': '15px', 'font-family': 'ariral,sans-serif' }}>
        {subcontent[j]}
      </li>
    );
  }
  for (var i in content) {
    listContent.push(
      <ul>
        <Typography variant="p" component="p" style={{ 'font-size': '15px', 'text-align': 'justify', 'padding-right': '24px', 'font-family': 'ariral,sans-serif' }}>
          {content[i]}
        </Typography>
      </ul>
    )
  }
  var list = <div style={{ 'padding-top': '60%', 'color': 'white', 'width': '85%', 'padding-left': '283px' }}>{listContent}<ul style={{ 'font-size': 'large', 'margin-left': '74px' }}>{listSubContent}


  </ul>
    <Typography style={{ 'fontSize': "15px" ,'marginLeft':"42px"}}>
      For demo contact <span style={{ 'color': "orange" }}> info@egnaroinc.com</span>
    </Typography>
  </div>

  return list;
}
function MatchProduct(props) {
  var indentation = {
    padding: {
      // paddingLeft:'127px',
      color: '#ff8a00',
      // textAlign:'center',
      textTransform: "uppercase",
      fontWeight: 'bold',
      fontFamily: 'ariral,sans-serif',
      paddingRight: "6%"
    }
  }
  var product = props.product
  var listProduct = [];
  var productArray = Config.product
  for (var i in productArray) {

    if (productArray[i].product === product) {
      listProduct.push(
        <div>

          <ProductContent content={productArray[i].content} subchildren={productArray[i].subchildren} />
        </div>
      )
    }
  }

  return listProduct;
}
export default Product
