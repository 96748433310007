import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
        root: {
                flexGrow: 1,
},
paper: {
backgroundColor:"#ff8a00"
},
}));
function Footer(props){

const classes = useStyles;
              const style=props.mobile?{'color':props.color,'padding-top':'17%'}:{'color':props.color,'padding-top':'8%','padding-left':'17%'}
              const color=props.mobile?{'color':'#ff8a00','font-weight':'bold','font-size':'21px','font-family':'ariral,sans-serif'}:{'color':'black','font-weight':'bold','font-size':'21px','font-family':'ariral,sans-serif'}


              const solutionStyle=props.mobile?{'color':"white",'font-size':"17px",'font-family':'ariral,sans-serif'}:{"color":'black','font-size':'17px','font-family':'ariral,sans-serif'}
              return (
                <div style={style}>

                <Grid container spacing={1}>
                      <Grid item xs={props.size}>
                        <ul style={{'list-style':'none'}} >
                            <li>
                                <Typography variant="h6" component="h2" style={color}>
                                  EGNARO
                                </Typography>
                            </li>

                            <li>
                                <Typography variant="h6" component="h2" className="footerTitle" style={{"font-size":"17px"}}>
                                  <a style={solutionStyle} href={window.location.origin+"/career"}> Career</a>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="h6" component="h2" className="footerTitle" style={{"font-size":"17px"}}>
                                  <a style={solutionStyle} href={window.location.origin+"/contact"}> Contact Us</a>
                                </Typography>
                            </li>

                        </ul>
                      </Grid>
                      <Grid item xs={props.size}>
                        <ul style={{'list-style':'none'}}>
                              <li>
                              <Typography variant="h6" component="h2" style={color}>
                              PRODUCTS
                              </Typography>
                              </li>
                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" >
                                <a style={solutionStyle} href={window.location.origin+"/product/automatter"}>Automatter</a>
                              </Typography>
                              </li>
                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" >
                              <a style={solutionStyle} href={window.location.origin+"/product/appify"}>  Appify</a>
                              </Typography>
                              </li>
                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" >
                              <a style={solutionStyle} href={window.location.origin+"/product/warp"}>  WARP</a>
                              </Typography>
                              </li>
                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" >
                              <a style={solutionStyle} href={window.location.origin+"/product/garuda"}> Garuda</a>
                              </Typography>
                              </li>


                        </ul>
                      </Grid>
                      <Grid item xs={props.mobile?props.size+6:props.size} >
                        <ul style={{'list-style':'none'}}>
                              <li>
                              <Typography variant="h6" component="h2" style={color}>
                                SOLUTIONS
                              </Typography>
                              </li>

                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" style={solutionStyle}>
                              <a style={solutionStyle} href={window.location.origin+"/solutions/opticalnetworking"}> Optical Networking</a>
                              </Typography>
                              </li>

                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" style={solutionStyle}>
                              <a style={solutionStyle} href={window.location.origin+"/solutions/highperformancecomputing"}>  High Performance Computing(HPC)</a>
                              </Typography>
                              </li>
                              <li>
                              <Typography variant="h6" component="h2" className="footerTitle" style={solutionStyle}>
                                <a style={solutionStyle} href={window.location.origin+"/solutions/r&doutsourcing"}>R&D Outsourcing</a>
                              </Typography>
                              </li>
                        </ul>
                      </Grid>
                </Grid>
                </div>
              )
      }

export default Footer;
