import React, { Component } from 'react';
import Config from "../Constants/Constants"
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Menubar from "../Component/Menubar"
import Footer from "../Component/Footer"
import halfbackground from "../img/halfbackground.png"
const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'blanchedalmond',
    fontSize: '10px'
  },
  commonbackground: {
    background: `url(${halfbackground})`,
    backgroundRepeat: "no-repeat,repeat",
    backgroundColor: "black !important",
    backgroundPosition: " top",
    // marginTop:" -41px"
  }
});
class Product extends Component {
  constructor(props) {
    super(props)
    var product = props.match.params.id
    this.state = {
      product: product,
      load: true
    }
  }

  render() {

    const { classes } = this.props;
    var paddingDistance = {}
    if (this.state.product === "opticalnetworking") {
      //      34
      paddingDistance = { 'padding-top': "12%" }
    }
    else if (this.state.product === "highperformancecomputing") {
      paddingDistance = { 'padding-top': "16%" }
    }
    else if (this.state.product === "r&doutsourcing") {
      paddingDistance = { 'padding-top': "18%" }
    }
    return (
      <div className={classes.commonbackground}>
        <CssBaseline />
        <Container fixed>
          <Grid container>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <MatchProduct solution={this.state.product} />
            </Grid>
          </Grid>
          <div style={paddingDistance}>
          </div>
          <Footer size={4} color="black" mobile={false} />
          <div style={{ 'padding-top': "10%" }}></div>
        </Container>
      </div>
    )
  }
}
function ProductContent(props) {
  var content = props.content;
  var listContent = [];
  var subcontent = props.subchildren;
  var listContent = [];
  var listSubContent = [];
  for (var j in subcontent) {
    listSubContent.push(
      <li style={{ 'font-size': '15px', 'font-family': 'ariral,sans-serif' }}>
        {subcontent[j]}
      </li>
    );
  }
  for (var i in content) {
    listContent.push(
      <ul>
        <Typography variant="p" component="p" style={{ 'font-size': '15px', 'text-align': 'justify', 'padding-right': '24px', 'font-family': 'ariral,sans-serif' }}>
          {content[i]}
        </Typography>
      </ul>
    )
  }
  var list = <div style={{ 'padding-top': '48%', 'color': 'white', 'width': '85%', 'padding-left': '283px' }}>{listContent}<ul style={{ 'font-size': 'large', 'margin-left': '74px' }}>{listSubContent}</ul>
    <Typography style={{ 'fontSize': "15px", 'marginLeft': "42px" }}>
      For demo contact <span style={{ 'color': "orange" }}> info@egnaroinc.com</span>
    </Typography>
  </div>
  return list;
}
function MatchProduct(props) {
  var indentation = {
    padding: {
      // paddingLeft:'127px',
      color: '#ff8a00',
      // textAlign:'center',
      textTransform: "uppercase",
      fontWeight: 'bold',
      fontSize: '22px',
      fontFamily: 'ariral,sans-serif',

    }
  }
  var product = props.solution;
  var listProduct = [];
  var productArray = Config.solution;
  for (var i in productArray) {

    if (productArray[i].solution === product) {
      listProduct.push(
        <div>
          <div style={{ 'width': "45%", 'padding-top': '6%', 'float': 'right', 'padding-right': '12%', 'text-align': 'center' }}>
            <Typography variant="h2" component="h2" className="UpperTransform" style={indentation.padding}>
              {productArray[i].title}
            </Typography>
            <img style={{ 'width': '30%', 'padding-top': "19%" }} src={productArray[i].img} alt={productArray[i].product} />
          </div>
          <ProductContent content={productArray[i].content} subchildren={productArray[i].subchildren} />
        </div>
      )
    }
  }

  return listProduct;
}
export default withStyles(useStyles)(Product)
