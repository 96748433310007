
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import React, { Component } from 'react';

function IconDetails(props){

var listArray=[]
var data=props.data
console.log(props.mode);
var isSolution=false
if(props.mode==="solution"){
    isSolution=true
}
var stylingMobile=props.mobile?{'text-align':'center','display':'table'}:{'display':'table'}
var imageStyle=props.mobile?{'color':'white','font-family':'ariral,sans-serif','font-weight':'bold'}:{'text-align':'center','color':'white','padding-left':'4px','font-size':'14px','font-family':"ariral,sans-serif",'font-weight':'bold'}
for(var i in data){

        listArray.push(
          <Grid item xs={props.size} style={stylingMobile}>
          <center>
          <a href={data[i].url} >
          <img src={data[i].img} style={{'width':'60%'}}/>
          <Typography style={imageStyle} variant="h6" component="h1" className="iconDetails" >
          {data[i].title}
          </Typography>
          </a>
          </center>
          </Grid>
        )
}

  return (
      <React.Fragment >
        {listArray}
      </React.Fragment>
    );
}

export default IconDetails
