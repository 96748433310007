import React, { Component } from 'react';
import Menubar from "../Component/Menubar"
import Config from "../Constants/Constants"
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import Footer from "../Component/Footer"
import Button from '@material-ui/core/Button';
import logo from "../img/favicon.png"
import background from "../img/IMAGES/hands.png"
function GetCanvas(){
    return null;
}

class MobileContact extends Component{
      constructor(props){
        super(props)
        this.state={
          mobile:false
        }
//        alert(navigator.platform);
        if (typeof window.orientation !== 'undefined') {
//          alert(window.orientation)
          this.state.mobile=true
          document.body.style.backgroundImage='none'
          document.body.style.background="black"

        }
      //  alert(this.state.mobile)
      }

      render(){

        const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
   margin: theme.spacing(1),
   color:"white"
 },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'blanchedalmond'
  },
}));
const classes = useStyles;
const styles={
  center:{
    textAlign:"center",
    fontSize:'large',
    fontWeight:'bold'

  },
  title:{
    textAlign:'justify',
    fontSize:'large'
  },
  highLight:{
    fontSize:'medium',
    fontWeight:'bold'
  },
  egnaro:{
    color:'chocolate',
    textAlign:'center',
    fontSize:'large',

  },
  textFont:{
    fontSize:'large',
    textAlign:'justify'
  },
  content:{
    backgroundColor:'whitesmoke'
  }
}
          const automatterUrl=Config.product[0].url;
          const appifyUrl=Config.product[1].url;
          const warpUrl=Config.product[2].url;
          const chowkidarUrl=Config.product[3].url;
              return(
                  <div style={{'background':'black'}}>
                  <React.Fragment>
                                  <div style={{'padding-right':'40px','padding-left':'40px'}}>
                                  <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'27px','font-family':'ariral,sans-serif','font-weight':"bold"}}>
                                  CONTACT US
                                </Typography>
                                <Typography variant="h6" component="p" style={{'color':'white','font-size':'17px','width':'100%','text-align':"justify",'font-family':'ariral,sans-serif'}}>
                                If you have a question or a comment, please email us at info@egnaroinc.com
                                </Typography>
                              </div>

                                   <Grid>


                                   <br/>
                                   <br/>
                                   <br/>
                                   <br/>
                                   </Grid>

                              <Footer size={6} color="white" mobile={true}/>
                        </React.Fragment>

                  </div>
              );
      }
}

export default MobileContact ;
