import React, { Component } from 'react';
import Config from "../Constants/Constants"
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Footer from "../Component/Footer"
import Button from '@material-ui/core/Button';

import IconDetails from "../Component/IconDetails"


function GetCanvas(){
    return null;
}

class Home extends Component{
      constructor(props){
        super(props)
        this.state={
          mobile:false
        }
//        alert(navigator.platform);
        if (typeof window.orientation !== 'undefined') {
//          alert(window.orientation)
          this.state.mobile=true
        }
      //  alert(this.state.mobile)
      }

      render(){

        const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
   margin: theme.spacing(1),
   color:"white"
 },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'blanchedalmond'
  },
}));
const classes = useStyles;
const styles={
  center:{
    textAlign:"center",
    fontSize:'large',
    fontWeight:'bold'

  },
  title:{
    textAlign:'justify',
    fontSize:'large'
  },
  highLight:{
    fontSize:'medium',
    fontWeight:'bold'
  },
  egnaro:{
    color:'chocolate',
    textAlign:'center',
    fontSize:'large',

  },
  textFont:{
    fontSize:'large',
    textAlign:'justify'
  },
  content:{
    backgroundColor:'whitesmoke'
  }
}
          const automatterUrl=Config.product[0].url;
          const appifyUrl=Config.product[1].url;
          const warpUrl=Config.product[2].url;
          const chowkidarUrl=Config.product[3].url;
              return(
                  <div className='backgroundColor'>
                  <React.Fragment>


                        <CssBaseline />


                            <Container fixed >

                              <Grid Container>
                                <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'47px'}}>
                                  ABOUT
                                </Typography>
                                <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'74px'}}>
                                  US
                                </Typography>
                                <Typography variant="h6" component="p" style={{'color':'white','font-size':'17px','width':'23%'}}>
                                Egnaro strives at creating a purely performance based, non-hierarchical, peer powered environment.

                                </Typography>
                              </Grid>

                                   <Grid>
                                        <Grid >
                                            <Typography variant="h6" component="h5" style={{'padding-top':'22%','padding-left':'10%','color':'white','font-size':'20px'}}>
                                             We live by the values that are aspired for .
                                            </Typography>
                                        </Grid>
                                   <div  style={{'text-align':'justify','padding-left':'59%','width':'89%','padding-top':"10%"}}>
                                          <Typography variant="h6" component="h2" style={{'font-size':'67px','color':"#ff8a00"}}>
                                             OUR MISSION
                                          </Typography>
                                          <Typography variant='h6' component='p' style={{'font-size':'26px','color':'white'}}>
                                              Mathematics,Statistics,Machine Learning,AI,Cluster computation and many other cutting edge technologies are being used for just selling ads.
                                          </Typography>
                                          <br/>
                                          <br/>

                                          <Button variant="outlined" style={{'color':'white','border':'1px solid white','float':'right'}}>
                                                  Read More
                                            </Button>
                                   </div>
                                   </Grid>
                                   <Typography variant='h6' component="h1" style={{'font-size':'47px','color':"white",'text-align':"center",'padding-top':'17%'}}>
                                     PRODUCTS
                                   </Typography>
                                   <Grid container >
                                             <Grid  container item={24} spacing={1} style={{'padding-left':"27%",'padding-top':"7%",'color':'white'}}>
                                                 <IconDetails  data={Config.product} size={2}  mobile={false}/>

                                             </Grid>
                                  </Grid>

                                            <Typography variant='h6' component="h1" style={{'font-size':'47px','color':"white",'text-align':"center",'padding-top':'4%'}}>
                                              SOLUTIONS
                                            </Typography>
                                            <Grid container >
                                                      <Grid  container item={24} spacing={1} style={{'padding-left':"27%",'padding-top':"1%",'color':'white'}}>
                                                          <IconDetails  data={Config.solution} size={2}  mobile={false}/>

                                                      </Grid>
                                           </Grid>
                                   <br/>
                                   <br/>
                              <Footer size={3} color="black" mobile={false}/>
                        </Container>
                        </React.Fragment>

                  </div>
              );
      }
}

export default Home ;
