import React, { Component } from 'react';
import Config from "../Constants/Constants"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Footer from "../Component/Footer"
function GetCanvas(){
    return null;
}

class MobileCarrer extends Component{
      constructor(props){
        super(props)
        this.state={
          mobile:false
        }
//        alert(navigator.platform);
        if (typeof window.orientation !== 'undefined') {
//          alert(window.orientation)
          this.state.mobile=true
          document.body.style.backgroundImage='none'
          document.body.style.background="black"

        }
      //  alert(this.state.mobile)
      }

      render(){

        const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
   margin: theme.spacing(1),
   color:"white"
 },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'blanchedalmond'
  },
}));
const classes = useStyles;
const styles={
  center:{
    textAlign:"center",
    fontSize:'large',
    fontWeight:'bold'

  },
  title:{
    textAlign:'justify',
    fontSize:'large'
  },
  highLight:{
    fontSize:'medium',
    fontWeight:'bold'
  },
  egnaro:{
    color:'chocolate',
    textAlign:'center',
    fontSize:'large',

  },
  textFont:{
    fontSize:'large',
    textAlign:'justify'
  },
  content:{
    backgroundColor:'whitesmoke'
  }
}
          const automatterUrl=Config.product[0].url;
          const appifyUrl=Config.product[1].url;
          const warpUrl=Config.product[2].url;
          const chowkidarUrl=Config.product[3].url;
              return(
                  <div style={{'background':'black'}}>
                  <React.Fragment>
                                  <div style={{'padding-right':'40px','padding-left':'40px'}}>
                                  <Typography variant="h6" component="h2" style={{'color':"#ff8a00",'font-size':'27px','font-family':'ariral,sans-serif','font-weight':'bold'}}>
                                  WORK
                                  ENVIRONMENT
                                </Typography>
                                <Typography variant="h6" component="p" style={{'color':'white','font-size':'17px','width':'100%','text-align':"justify",'font-family':'ariral,sans-serif'}}>
                                At Egnaro, we believe productivity is where comfort is. We keep our work environment informal but professional. We being foodies and fun lovers, you will be pampered to free healthy food and awesome perks including recreational facilities. Employees are also given the flexibility to collectively evolve their environment as they deem fit.
                                </Typography>
                              </div>

                                   <Grid>

                                   <div  style={{'text-align':'justify','padding-top':"10%",'padding-right':'40px','padding-left':'40px'}}>
                                          <Typography variant="h6" component="h2" style={{'font-size':'27px','color':"#ff8a00",'font-family':'ariral,sans-serif','font-weight':'bold'}}>
                                             HOW TO APPLY
                                          </Typography>
                                          <Typography variant='h6' component='p' style={{'font-size':'16px','color':'white','font-family':'ariral,sans-serif'}}>
                                            Egnaro is an equal-opportunity employer with an eye towards excellence. If you think that you’re a good fit, please send your resume to careers@egnaroinc.com
                                          </Typography>
                                          <br/>
                                          <br/>

                                   </div>
                                   <br/>
                                   <br/>
                                   <br/>
                                   <br/>
                                   </Grid>

                              <Footer size={6} color="white" mobile={true}/>
                        </React.Fragment>

                  </div>
              );
      }
}

export default MobileCarrer ;
